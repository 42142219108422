<template>
     <div class="container-fluid py-4 add-student">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">  تعديل الفيديو :  دورة {{courseName}}  </h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
                 <form  @submit='updateVideo' role="form" class="text-start mt-3">
                            <div class='row'>
                     <div class="mb-3 col-lg-6 ">
                    <label> عنوان الفيديو<span class="red">*</span></label>
                  <vmd-input
                    id="date-input"
                    type="text"
                   placeholder="  عنوان الفيديو "
                   v-model='video.name'
                   required
                  />
                  </div>
                    <div class="mb-3 col-lg-6 ">
                    <label>   عنوان الفيديو بالإنجليزية<span class="green"> (اختيارى) </span></label>
                  <vmd-input
                    id="date-input"
                    type="text"
                   placeholder=" عنوان الفيديو بالإنجليزية "
                   v-model='video.name_en'
                  />
                  </div>
                  </div>
                 <div class='row' >
                    <div class="mb-3 col-lg-6">
                      <div class="row">
                        <div class="mb-3 mt-4 col-lg-3">
                          <vmd-checkbox id="free-video" type="text" v-model='video.free' :value="true">
                            فديو مجاني
                        </vmd-checkbox>
                        </div>
                        <div class="mb-3 col-lg-9" v-if="!video.free">
                          <label>  قيمة الفيديو<span class="red">*</span></label>
                          <vmd-input
                            id="date-input"
                            type="text"
                            placeholder=" قيمة الفيديو  "
                            v-model='video.cost'
                            :required="!video.free"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                <div class="row"  v-if="video.media?.length">
                   <h5 id='data' class='video-header' style="text-align: right"> <span>الفيديو </span>
        </h5>
            <div class="row">
              <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
            <div class="row">
                  <div class="col-lg-6 mb-3 text-center" v-if="video.media?.length">
                      <label> الفيديو الحالي </label>
                 <div>
             <video width="320" height="240" controls class="img-thumbnail">
          <source :src="video.media[0].video_url" type="video/mp4">
      </video>
                </div>
                </div>
                  <div class="col-lg-6 mb-3 text-center" v-else>
                      <h5>لا يوجد فيديو</h5>
                </div>
                 <div class="col-lg-6 mb-3" style="position:relative">
                 <label> تعديل الفيديو (mp4,x-flv,x-mpegURL,MP2T,3gpp,x-msvideo,x-ms-wmv)<span class="red">*</span></label><br>
                 <div>
                 <vue-dropzone
                  ref="myVueDropzone"
                  id="dropzone"
                  :options="dropzoneOptions"
                  @vdropzone-sending="sendingEvent"
                  @vdropzone-upload-progress='checkProgress'
                  @vdropzone-file-added="getFileCount"
                  @vdropzone-success="Success"
                />
                </div>
                </div>
                  <a
                  @click="removed"
                  style="left:10% !important"
                    type='button '
                    class="my-4 mb-2 mini"
                    id='del-vid'
                    variant="gradient"
                    color="danger"
                    > حذف</a>
                </div>
                  </div>
                    </div>
                  </div>
                  </div>
                  <div class="row" v-else>
                      <div class="mb-3 col-lg-6 ">
                    <label>  رابط الفيديو</label>
                    <a class="link vid-url" :href="video.url" target="_blank">{{video.url}}</a>
                </div>
                            <div class="mb-3 col-lg-6">
                  <label>  تغيير</label>
                  <vmd-input
                    id="date-input"
                    type="text"
                    placeholder=" رابط الفيديو "
                    v-model="videoUrl"
                  />
                </div>
                  </div>
                       <div class="row text-center back-btn">
                    <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                    > حفظ</vmd-button
                  >
                </div>
                 <div class="text-center col-lg-6">
                  <vmd-button
                   @click='goBack($event)'
                    class="my-4 mb-2 "
                    type='btn'
                    variant="gradient"
                    color="warning"
                    > رجوع</vmd-button
                  >
                </div>
                       </div>
               </form>
          </div>
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import VmdButton from '@/components/VmdButton.vue'
import VmdInput from '@/components/VmdInput.vue'
import vueDropzone from 'vue2-dropzone-vue3'
import swal from 'sweetalert'
import $ from 'jquery'
import VmdCheckbox from '@/components/VmdCheckbox.vue'

export default {
  name: 'EditVideo',
  components: {
    VmdButton,
    VmdInput,
    VmdCheckbox,
    vueDropzone
  },
  data () {
    return {
      video: {},
      videoUrl: '',
      mediaId: '',
      vidSrc: null,
      courseName: '',
      courseId: '',
      allfiles: 0,
      videosLength: 0,
      videoProgress: 0,
      sentSize: 0,
      videoSize: 0,
      dropzoneOptions: {
        url: `${this.$hostUrl}chunk-upload`,
        thumbnailWidth: 150,
        maxFilesize: 3000,
        addRemoveLinks: false,
        autoProcessQueue: true,
        timeout: 2000000,
        chunking: true,
        chunkSize: 2000000,
        headers: { Authorization: 'Bearer ' + localStorage.token },
        dictDefaultMessage: '<i class="fas fa-cloud-upload-alt"></i>تحميل الفيديو'

      },
      headers: { Authorization: 'Bearer ' + localStorage.token },
      errors: []
    }
  },
  async created () {
    await this.$http
      .get(`${this.$hostUrl}videos/${this.$route.params.id}`)
      .then((response) => {
        this.video = response.data.data
        this.video.free = (response.data.data.free === 1)
        this.courseName = this.video.course.name
        this.courseId = this.video.course.id
        if (this.video.media) {
          this.vidSrc = this.video.media
        }
      })
  },
  mounted () {
    this.$http
      .get(`${this.$hostUrl}videos/${this.$route.params.id}`)
      .then((response) => {
        this.video = response.data.data
      })
    console.log(this.video)
    // this.vidSrc = this.video.media[0].video_url
  },
  methods: {
    onFileChange (event, index) {
      const file = event.target.files[0]
      this.allPdfFiles[index].pdf_file = file
    },
    checkProgress (file, progress, bytesSent) {
      this.videoProgress = parseInt(progress) + ' % '
      let sentSize = bytesSent / 1000000
      sentSize = sentSize.toFixed(1)
      this.sentSize = sentSize
      let remainSize = (this.videoSize) - (this.sentSize)
      remainSize = remainSize.toFixed(1)
      // add data to appended elements
      $('.dz-details h4').text(this.videoProgress)
      $('.dz-details .sent-size').text(' تم تحميل : ' + ' MB ' + this.sentSize)
      $('.dz-details .remain-size').text('  المتبقي : ' + ' MB ' + remainSize)
    },
    sendingEvent (file, xhr, formData) {
      // append video progress details
      $('.dz-details').append('<h4 class="ratio">' + this.videoProgress + '</h4>')
      $('.dz-details').append('<span class="sent-size"></span>')
      $('.dz-details').append('<h6 class="remain-size"></h6')
      let videoSize = file.size / 1000000
      videoSize = videoSize.toFixed(1)
      this.videoSize = videoSize
    },
    removed () {
      $('#dropzone').removeClass('dz-started')
      $('#dropzone .dz-preview').remove()
      $('#del-vid').addClass('hide')
      this.allfiles--
      this.videosLength--
      const formData = new FormData()
      formData.append('_method', 'DELETE')
      this.errors = this.postRequest(formData, this.$hostUrl + 'remove/media/' + this.singleVideo, '')
    },
    getFileCount () {
      this.allfiles++
      console.log('videos count', this.allfiles)
    },
    Success (file, response) {
      swal({ title: response.message, buttons: 'ok' })
      this.videosLength++
      this.videos = response.data.media_id
      this.mediaId = response.data.media_id
      this.singleVideo = response.data.id
      $('#del-vid').addClass('display')
      $('#del-vid').show()
      $('#del-vid').removeClass('hide')
      console.log('video', this.videos, 'succes videos =>', this.videos?.length)
    },
    async updateVideo (e) {
      e.preventDefault()
      const formData = new FormData()
      const videoCourses = []
      $.each(this.selectedCourse, (key, val) => {
        videoCourses.push(val.id)
      })
      const currentCourse = []
      currentCourse.push(this.$route.params.id)
      this.videoCourses = videoCourses
      this.videoCourses.concat(currentCourse)
      // if (this.videoCourses.length) {
      //   formData.append(
      //     'course_id',
      //     JSON.stringify(this.videoCourses.concat(currentCourse))
      //   )
      // } else {
      //   formData.append('course_id', JSON.stringify(currentCourse))
      // }
      formData.append('course_id', this.courseId)
      formData.append('name', this.video.name)
      if (this.video.name_en) {
        formData.append('name_en', this.video.name_en)
      }
      formData.append('free', this.video.free ? 1 : 0)
      formData.append('cost', this.video.free ? 0 : this.video.cost)
      formData.append('_method', 'PUT')
      formData.append('type', this.video.type)
      if (this.mediaId) {
        formData.append('media_id', this.mediaId)
      }
      if (this.video?.url && this.videoUrl) {
        formData.append('url', this.videoUrl)
      } else if (this.video?.url && !this.videoUrl) {
        formData.append('url', this.video.url)
      }
      formData.append('watch_limit', 5)
      await this.$http
        .post(
            `${this.$hostUrl}videos/${this.$route.params.id}`,
            formData, '/courses'
        )
        .then((response) => {
          if (!response.data.error) {
            swal({ title: response.data.message, buttons: 'تم' })
            this.$router.push({ path: '/courses' })
          } else {
            this.errors = response.data.data
          }
        }).catch(function (error) {
          if (Object.prototype.hasOwnProperty.call(error, 'response')) {
            const data = (error.response || {}).data
            if (data.error) {
              swal(data.message)
            }
          }
        })
    },
    goBack (e) {
      e.preventDefault()
      this.$router.push({ path: '/courses' })
    }
  }
}
</script>
<style >
.dropzone__box {
      width: 100% !important;
    background: #f8f9fa !important;
    min-height: 40px !important;
    cursor: pointer !important;
}
.video-header{
  justify-content: center;
  align-items: center;
  padding:18px !important;
}
.video-header .tab-div{
  width:75% !important;
  margin-top:0 !important
}
.tab-div li{
 width:25% !important;
}
.back-btn{
  display:flex;
  justify-content: center;
  margin-top: 20px !important;
}
</style>
